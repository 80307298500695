<template>
  <div>
    <v-container grid-list-xl>
      <app-card>
        <div>Uw account is nog niet compleet aangemaakt. Neem contact op met <a href="mailto:support@driveme.nl">support@driveme.nl</a></div>
        <div class="mt-5"><v-btn
          id="login-again-btn"
          color="pink"
          dark
          @click="$store.dispatch('logoutUserFromFirebase', $router)">Opnieuw inloggen</v-btn></div>
      </app-card>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
